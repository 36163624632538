@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Outfit:wght@400;500&family=Roboto&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.heading-text {
  font-family: 'Abril Fatface', serif;
}
.title-text {
  font-family: 'Outfit', sans-serif;
}

.center-logo {
  text-align: -webkit-center;
}
.privacyText {
  color: #343434;
  font-size: 18px;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  line-height: 28px;
}
.footerText {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

@media (min-width: 980px) {
  .right-container {
    background-image: url('./images/Frame\ 1.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
  .left-container{
    background-image: url('./images/Frame\ 2.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
@media (min-width: 1440px) {
  .right-container {
    background-size: contain;
  }
}
